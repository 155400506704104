import { useState, useCallback, useEffect, useRef } from "react";
import { GroupBy } from "app/data/helpers";
import { useAppDispatch, useAppSelector, useSelectSearch } from "hooks";
import {
  selectAccountGroups,
  selectDrawerSelectedAccountId,
  selectObjectsState,
  selectObjectTypesState,
  selectQueriedObjectsState,
  selectReportsState
} from "app/data/selectors";
import {
  getAllAccountGroupsThunk,
  getAllObjectsThunk,
  getInitialObjectsForDropdownListThunk,
  getObjectsByQueryThunk,
  getObjectTypesByAccountThunk,
  resetPreloadedObjects
} from "app/features";

const useReportCommonStates = () => {
  const dispatch = useAppDispatch();
  const isFetching = useRef(false);

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );

  const { objects, loading: objectsLoading } = useAppSelector(
    selectQueriedObjectsState
  );

  const { basicObjects, initialObjectsCount, preloadedObjects } =
    useAppSelector(selectObjectsState);

  const { accountGroups } = useAppSelector(selectAccountGroups);

  const {
    report,
    loading: reportsLoading,
    reportsFilterData,
    reportsHasExceededLimit
  } = useAppSelector(selectReportsState);

  // Local State
  const [filterOpen, setFilterOpen] = useState(false);

  const [dateFormat, setDateFormat] = useState<GroupBy>("day");

  const [dateValues, setDateValues] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const [localReport, setLocalReport] = useState<any>([]);

  const [selectedLocalIds, setSelectedLocalIds]: Array<any> = useState([]);

  const selectedLocalObjects = useSelectSearch(selectedLocalIds, objects);

  const handleOnChangeReportFilterForm = (val: any) => {
    setSelectedLocalIds(val);
  };

  const handleObjectsSearch = useCallback(
    (value: string) => {
      dispatch(
        getObjectsByQueryThunk({
          accountId: drawerSelectedAccountId || "",
          params: {
            itemsPerPage: 50,
            page: 1,
            where: [{ field: "name", value, type: 0 }]
          },
          minimal: true
        })
      );
    },
    [drawerSelectedAccountId]
  );

  const preloadInitialObjects = async () => {
    isFetching.current = true;
    const { type, payload } = await dispatch(
      getInitialObjectsForDropdownListThunk(drawerSelectedAccountId || "")
    );

    if (type === "objects/getInitialObjectsForDropdownListThunk/fulfilled") {
      if (payload < 26) {
        await dispatch(getAllObjectsThunk(drawerSelectedAccountId || ""));
      } else {
        dispatch(resetPreloadedObjects());
      }
    }
    isFetching.current = false;
  };

  useEffect(() => {
    if (isFetching.current === false) {
      preloadInitialObjects();
      dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId || ""));
      dispatch(getObjectTypesByAccountThunk(drawerSelectedAccountId || ""));
    }
  }, [drawerSelectedAccountId]);

  return {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objects,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    selectedLocalIds,
    handleOnChangeReportFilterForm,
    basicObjects,
    initialObjectsCount,
    reportsFilterData,
    reportsHasExceededLimit,
    preloadedObjects
  };
};

export default useReportCommonStates;
